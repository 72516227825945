/*@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');*/

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

@font-face {
    font-family: 'Satoshi-Light';
    src: url('./fonts/satoshi/Satoshi-Light.woff2') format('woff2'),
    url('./fonts/satoshi/Satoshi-Light.woff') format('woff'),
    url('./fonts/satoshi/Satoshi-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi-LightItalic';
    src: url('./fonts/satoshi/Satoshi-LightItalic.woff2') format('woff2'),
    url('./fonts/satoshi/Satoshi-LightItalic.woff') format('woff'),
    url('./fonts/satoshi/Satoshi-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'Satoshi-Regular';
    src: url('./fonts/satoshi/Satoshi-Regular.woff2') format('woff2'),
    url('./fonts/satoshi/Satoshi-Regular.woff') format('woff'),
    url('./fonts/satoshi/Satoshi-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi-Italic';
    src: url('./fonts/satoshi/Satoshi-Italic.woff2') format('woff2'),
    url('./fonts/satoshi/Satoshi-Italic.woff') format('woff'),
    url('./fonts/satoshi/Satoshi-Italic.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'Satoshi-Medium';
    src: url('./fonts/satoshi/Satoshi-Medium.woff2') format('woff2'),
    url('./fonts/satoshi/Satoshi-Medium.woff') format('woff'),
    url('./fonts/satoshi/Satoshi-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi-MediumItalic';
    src: url('./fonts/satoshi/Satoshi-MediumItalic.woff2') format('woff2'),
    url('./fonts/satoshi/Satoshi-MediumItalic.woff') format('woff'),
    url('./fonts/satoshi/Satoshi-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'Satoshi-Bold';
    src: url('./fonts/satoshi/Satoshi-Bold.woff2') format('woff2'),
    url('./fonts/satoshi/Satoshi-Bold.woff') format('woff'),
    url('./fonts/satoshi/Satoshi-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi-BoldItalic';
    src: url('./fonts/satoshi/Satoshi-BoldItalic.woff2') format('woff2'),
    url('./fonts/satoshi/Satoshi-BoldItalic.woff') format('woff'),
    url('./fonts/satoshi/Satoshi-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: italic;
}


@font-face {
    font-family: 'Satoshi-Black';
    src: url('./fonts/satoshi/Satoshi-Black.woff2') format('woff2'),
    url('./fonts/satoshi/Satoshi-Black.woff') format('woff'),
    url('./fonts/satoshi/Satoshi-Black.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Satoshi-BlackItalic';
    src: url('./fonts/satoshi/Satoshi-BlackItalic.woff2') format('woff2'),
    url('./fonts/satoshi/Satoshi-BlackItalic.woff') format('woff'),
    url('./fonts/satoshi/Satoshi-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
    font-style: italic;
}


html {
    height: 100%;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.markdown-body > pre {
    padding-left: 0 !important;
    padding-right: 0 !important;
}